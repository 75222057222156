@import 'styles/spacing.scss';
@import 'styles/color.scss';
@import 'styles/font.scss';

.WaterMeterWm1000nbForm {
  &__text-wrapper {
    margin: $spacing-l 0;
  }

  &__text-wrapper-legend {
    color: $color-gray-extra-light;
    font-style: italic;
  }

  &__title {
    font-weight: bold;
    margin-bottom: $spacing-xxs;
  }

  &__wrapper {
    border: 1px solid $color-text-disabled;
    border-radius: $spacing-xs;
    padding: $spacing-xl;
  }

  &__wrapper-row {
    display: flex;
    gap: $spacing-m;
  }
}
