@import 'styles/spacing.scss';

.TabDevices {
  &__button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: $spacing-m;
  }
}
