@import 'styles/font.scss';
@import 'styles/spacing.scss';
@import 'styles/color.scss';

.ManualQRAccessDrawer {
  &__wrapper .ant-drawer-header {
    padding: $spacing-xl $spacing-xxxl;
  }

  &__wrapper .ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse;
  }

  &__title {
    font-size: $font-size-xl;
    font-weight: 600;
  }

  &__wrapper .ant-drawer-header-title button {
    margin-right: 0;
  }

  &__wrapper .ant-drawer-body {
    padding: $spacing-xl $spacing-xxxl $spacing-xxxxxl $spacing-xxxl;
  }

  &__wrapper footer {
    position: absolute;
    width: 100%;
    background-color: $color-text-white;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: $spacing-l;
    gap: $spacing-m;
    border-top: $spacing-one-pixel solid $color-gray-extra-extra-light;
  }

  &__main {
    min-width: 400px;
  }

  &__main-wrapper {
    padding-bottom: $spacing-xl;
  }

  &__main-wrapper-input-time {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  &__main-wrapper-input {
    display: flex;
    flex-direction: column;
    gap: $spacing-s - $spacing-xxxs;
  }

  &__main-wrapper-time-picker-input {
    display: flex;
    flex-direction: column;
    width: 48%;
  }

  &__input-label-range-date {
    font-weight: 700;
    margin-bottom: $spacing-xxxs;
  }

  &__main-wrapper-input-description {
    color: $color-text-grey;
  }

  &__main-wrapper-input-range-picker {
    width: 100%;
  }

  &__tag {
    margin-top: $spacing-s !important;
  }

  &__main-wrapper-input-time-picker {
    width: 100%;
  }

  &__main-label {
    margin-bottom: $spacing-xs;
  }

  &__main-box {
    width: 100%;
    border: $spacing-one-pixel solid $color-border-grey-light;
    padding-left: $spacing-l;
    padding-right: $spacing-l;
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xs;
    margin-top: $spacing-s;
    margin-bottom: $spacing-s;
    cursor: pointer;
  }

  &__main-box--open {
    cursor: default;
  }

  &__main-box-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__main-box-title {
    font-weight: 700;
    margin-bottom: $spacing-xl;
  }

  &__input-label-QR-code {
    font-weight: 700;
    margin-bottom: $spacing-s;
  }

  &__required-fields {
    font-size: $font-size-s;
    color: $color-text-grey;
  }

  &__email-tag-list {
    margin-top: $spacing-s;
    margin-bottom: $spacing-s;
  }

  &__email-tag {
    margin-top: $spacing-xxs !important;
  }

  &__title {
    margin-right: $spacing-s;
  }
}
